import { polyfillLoader } from '@bolt/core/polyfills';

polyfillLoader.then(() => {
  import(
    /*
    webpackMode: 'eager',
    webpackChunkName: 'bolt-code-snippet'
  */ './code-snippet.standalone.js'
  );
});
